<template>
  <div v-if="type==1" class="threads">
    <!-- 帖子 -->
    <div class="content">
      <div :class="listType == 1 ? 'box_top row_left' : 'box_top row_right'">
        <div v-show="thumb" class="thumb">
          <img :src="thumb" class="div_card" :class="listType == 1 ? 'img_left' : 'img_right'">
        </div>
        <div class="info_box">
          <div class="title">
            <p>{{ atitle }}</p>
          </div>
          <div v-if="listType == 1" class="midInfo">
            <span class="uname">{{ username }}&nbsp;</span>
            <span v-show="createTime" class="time">发布于 {{ formatDate(createTime) }}</span>
          </div>
          <div v-if="listType == 1" class="botInfo">
            <img class="view" src="@/static/images/my_index/eye1_img.png">
            <p class="viewNum">{{ vNum ? vNum : 0 }}人浏览</p>
            <img class="reply" src="@/static/images/my_index/kuang1_img.png">
            <p class="replyNum">{{ cNum ? cNum : 0 }}人跟帖</p>
          </div>
        </div>
      </div>
      <div v-if="listType == 2" class="botInfoNew">
        <div class="botInfo">
          <div class="viewBox">
            <img class="view" src="@/static/images/my_index/eye1_img.png">
            <p class="viewNum">{{ vNum ? vNum : 0 }}人浏览</p>
          </div>
          <div class="replyBox">
            <img class="reply" src="@/static/images/my_index/kuang1_img.png">
            <p class="replyNum">{{ cNum ? cNum : 0 }}人跟帖</p>
          </div>
        </div>
        <p class="bot_time">{{ formatDate(createTime) }}</p>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="botLine"></div>
  </div>
  <div v-else-if="type==2" class="lessons">
    <div :class="listType == 5 ? 'les_content' : 'content'">
      <div class="box_top row_left">
        <div class="thumb">
          <img v-if="thumb" class="img_left" :src="thumb">
          <img v-else class="img_left" alt="暂无图片">
        </div>
        <div class="info_box">
          <div class="title">
            <p>{{ atitle }}</p>
          </div>
          <!-- 已购课程 -->
          <div v-if="listType == 1" class="midInfo">
            <p v-if="isEnd" class="expire">限时免费已结束</p>
            <p v-else class="expire">
              <span>{{ getTime(date).includes('2099')?'无限期':'有效期: '+getTime(date) }}</span>
              <span style="margin: 0 5px;">|</span>
              <span>已学习: <span style="color: #ee2e2e;">{{ lessonLooked }}</span>/{{ lessonLong }}课</span>
            </p>
            <p class="studyNoClass">
              <span v-if="lessonType==2 && isAddClass==2 && !isZkb">
                <span>等待分班</span>
              </span>
            </p>
          </div>
          <!-- 未付款 -->
          <div v-else-if="listType == 2" class="midInfo">
            <p class="orders">订单号：{{ oderSn }}</p>
            <p class="orders_np">
              应付：<span class="money1">￥{{ price }}</span>
            </p>
          </div>
          <!-- 观看记录 -->
          <div v-else-if="listType == 3" class="midInfo">
            <p class="expire">已听{{ havelearned }}/{{ allcourse }}课时</p>
            <p class="expire">观看时间：{{ getTime(date) }}</p>
          </div>
          <!-- 课程收藏 -->
          <div v-else-if="listType == 4" class="midInfo1">
            <div class="student">
              <img class="studentIcon" src="@/static/images/my_index/lesson1.png">
              <p class="viewNum">{{ vNum ? vNum : 0 }}人学习</p>
            </div>
            <div class="rate">
              <img class="rateIcon" src="@/static/images/my_index/flw.png">
              <p class="replyNum">{{ cNum ? cNum : 0 }}%好评</p>
            </div>
          </div>
          <!-- 浏览记录 -->
          <div v-else-if="listType == 5" class="midInfo">
            <p class="expire">浏览时间：{{ getTime(date) }}</p>
          </div>
          <!-- 课程价格 -->
          <div v-if="listType == 4" class="midInfo">
            <p class="orders">
              <span class="money">￥{{ price }}</span>
            </p>
          </div>
        </div>
      </div>
      <div v-show="((listType == 1 && showBuyMenus('1')) || listType == 2)" class="botMenus">
        <div v-if="listType == 1 && showBuyMenus('1')" style="width: 100%;" class="botMenusBg">
          <div class="botMenusLeft">
            <span v-if="showMoreLessonMenu && showRegState == 1 && lessonType !==1 && !isZkb" class="record" @click.stop="clickWrite">填写学员登记表</span>
            <span v-else-if="showMoreLessonMenu && showRegState == 2 && lessonType !==1 && !isZkb" class="record" @click.stop="clickWrite">查看学员登记表</span>
            <span v-if="showMoreLessonMenu && agreementId>0 && !isZkb" class="invoice" @click.stop="lookAgreement()">查看协议</span>
            <span v-if="showMoreLessonMenu && isGift" class="span_edi_address" @click.stop="clickEdiAddress">修改地址</span>
            <span v-if="showMoreLessonMenu && listType == 1 && isInvoice == 1" class="invoice" @click.stop="invoice">{{ invoiceStatus == 1 ? '查看发票' : '去开发票' }}</span>
          </div>
          <div class="botMenusRight" @click.stop="showMoreMenu">
            <img src="@/static/images/my_index/more_point.png" alt="">
          </div>
          <!-- 1、立即听课 2、等待分班 3、进入班级 -->
          <!-- <span v-if="lessonType==2 && !isZkb">
            <span v-if="isAddClass==1" class="lesson_listen" @click.stop="listenLesson">立即听课</span>
            <span v-else-if="isAddClass==3" class="lesson_listen" @click.stop="listenLesson">进入班级</span>
            <span v-else class="lesson_noclass" @click.stop="noClass">等待分班</span>
          </span> -->
        </div>
        <div v-else-if="listType == 2" class="noPayBtnsBox">
          <span class="cancelPay" @click.stop="clickCancelPay">取消订单</span>
          <span class="payNow" @click.stop="clickPayNow">付款</span>
        </div>
      </div>
    </div>
    <!-- 分割线 -->
    <!-- <div class="botLine"></div> -->
  </div>
</template>

<script>
import tool from '@/utils/tools'

export default {
  props: ['type', 'isZkb', 'isGift', 'agreementId', 'listType', 'thumb', 'atitle', 'username', 'createTime', 'vNum', 'cNum',
    'date', 'lessonLooked', 'lessonLong', 'oderSn', 'price', 'showRegState', 'isAddClass', 'lessonType', 'isInvoice',
    'invoiceStatus', 'isEnd', 'havelearned', 'allcourse'],
  data() {
    return {
      showMoreLessonMenu: false
    }
  },
  methods: {
    // 查看协议
    lookAgreement() {
      this.$emit('lookAgreement')
    },
    formatDate(time) {
      if (time !== null && time !== '') {
        time = time * 1000
        const date = new Date(time)
        return tool.formatDate(date, 'yyyy-MM-dd')
      } else {
        return ''
      }
    },
    showBuyMenus(t) {
      if (this.lessonType == 2 ||
        (this.showRegState == 1 && this.lessonType !== 1) ||
        (this.showRegState == 2 && this.lessonType !== 1) ||
        (this.listType == 1 && this.isInvoice == 1)) {
        return true
      }
      return false
    },
    getTime(data) {
      var str = data.slice(0, 10)
      return str
    },
    // 点击展示更多按钮
    showMoreMenu() {
      this.showMoreLessonMenu = !this.showMoreLessonMenu
      console.log('展示更多', this.showMoreLessonMenu)
    },
    clickWrite() {
      this.$emit('writeRecord')
    },
    clickEdiAddress() {
      this.$emit('clickEdiAddress')
    },
    clickCancelPay() {
      this.$emit('cancelPay')
    },
    clickPayNow() {
      this.$emit('payNow')
    },
    listenLesson() {
      this.$emit('listenLessons')
    },
    noClass() {
    },
    invoice() {
      this.$emit('makeInvoice')
    }
  }
}
</script>

<style lang="scss" scoped>
.threads {
  width: calc(100% - 40px);
  padding: 20px 20px 0;
  display: inline-block;
}
.content {
  padding: 24px 24px;
  background-color: #fff;
  border-radius: 16px;
  // border-bottom: 1px solid #eee;
}
.les_content {
  margin: 10px 30px 10px 30px;
  width: calc(100% - 60px);
  // border-bottom: 1px solid #eee;
}
.box_top {
  display: flex;
}
.row_left {
  flex-direction: row;
}
.row_right {
  flex-direction: row-reverse;
}
.thumb {
  margin-top: 10px;
  width: 278px;
  height: 145px;
  img {
    width: 258px;
    height: 145px;
    object-fit: cover;
    border-radius: 10px;
  }
  .img_left {
    margin-right: 20px;
  }
  .img_right {
    margin-left: 20px;
  }
}
.info_box {
  //自动计算剩余宽度
  flex: 1;
  position: relative;
  .midInfo {
    // 需要固定midInfo的离上间距，选择定位到父元素离底部的30px
    color: #999;
    font-size: 28px;
    margin-top: 10px;
    .expire {
      font-size: 24px;
    }
    .study {
      color: #ee2e2e;
      font-size: 24px;
    }
    .studyNoClass {
      text-align: right;
      color: #999;
      font-size: 24px;
    }
    .orders {
      font-size: 24px;
    }
    .orders_np {
      margin-top: 5px;
      text-align: right;
      color: #333;
      font-size: 24px;
    }
    .money {
      color: #ee2e2e;
      font-size: 24px;
    }
    .money1 {
      color: #333;
      font-size: 24px;
    }
  }
}
.title {
  // background-color: aqua;
  // height: 80px;
  padding-top: 8px;
  font-size: 30px;
  color: #333;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.botInfoNew {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.botInfo {
  position: relative;
  margin-top: 15px;
  height: 30px;
  display: flex;
  align-items: center;
}
.viewBox {
  height: 30px;
  display: flex;
  align-items: center;
}
.replyBox {
  height: 30px;
  display: flex;
  align-items: center;
}
.view {
  width: 28px;
  height: 18px;
}
.viewNum {
  display: inline-block;
  margin-left: 8px;
  color: #999;
  font-size: 24px;
}
.reply {
  margin-left: 30px;
  width: 26px;
  height: 22px;
}
.replyNum {
  display: inline-block;
  margin-left: 8px;
  color: #999;
  font-size: 24px;
}
.bot_time {
  margin-top: 15px;
  // width: 150px;
  // height: 30px;
  line-height: 30px;
  text-align: right;
  color: #999;
  font-size: 24px;
  // position: absolute;
  // right: 20px;
}
.midInfo1 {
  margin-top: 8px;
  color: #999;
  font-size: 28px;
  display: flex;
  align-items: center;
  .student {
    display: flex;
    align-items: center;
  }
  .rate {
    display: flex;
    align-items: center;
  }
}
.studentIcon {
  width: 32px;
  height: 26px;
}
.rateIcon {
  margin-left: 30px;
  width: 29px;
  height: 29px;
}

// .botLine {
//   // width: 100%;
//   // height: 0.5px;
//   // background-color: #eee;
// }

.lessons {
  width: calc(100% - 40px);
  padding: 20px 20px 0;
  display: inline-block;
}
.botMenus {
  margin-top: 24px;
  height: 50px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  .botMenusBg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .botMenusLeft {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
  }
  .botMenusRight {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: 8px;
      display: block;
    }
  }
  .span_edi_address {
    height: 48px;
    padding: 0px 12px;
    margin-right: 8px;
    line-height: 48px;
    display: inline-block;
    border-radius: 25px;
    background-color: white;
    color: #ee2e2e;
    border: 1px solid #ee2e2e;
  }
  .record {
    height: 50px;
    width: 200px;
    line-height: 50px;
    display: inline-block;
    margin-right: 8px;
    border-radius: 25px;
    color: #ee2e2e;
    border: 1px solid #ee2e2e;
  }
  .invoice {
    height: 48px;
    padding: 0px 12px;
    margin-right: 8px;
    line-height: 48px;
    display: inline-block;
    border-radius: 25px;
    color: #ee2e2e;
    border: 1px solid #ee2e2e;
  }
  .lesson_listen {
    height: 50px;
    width: 200px;
    line-height: 50px;
    display: inline-block;
    border-radius: 25px;
    background-color: #ee2e2e;
    margin-left: 25px;
  }
  .lesson_noclass {
    height: 50px;
    width: 130px;
    line-height: 50px;
    display: inline-block;
    border-radius: 25px;
    color: #999999;
    background-color: #fff;
    margin-left: 20px;
  }
  .noPayBtnsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-box-pack: end;
    -moz-justify-content: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .cancelPay {
    margin-right: 25px;
    color: #999;
    height: 50px;
    width: 130px;
    line-height: 50px;
    border: 2px solid #999;
    display: inline-block;
    border-radius: 10px;
    background-color: #fff;
  }
  .payNow {
    height: 50px;
    width: 130px;
    line-height: 50px;
    border: 2px solid #ee2e2e;
    display: inline-block;
    border-radius: 10px;
    background-color: #ee2e2e;
  }
}
.invoice1 {
  height: 50px;
  padding: 0px 24px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 25px;
  color: #ee2e2e;
  border: 1px solid #ee2e2e;
}
.div_card{
  box-shadow: 2px 2px 2px 2px #aaa9a9;
}
</style>
